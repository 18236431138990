import ContactMap from "./ContactMap"

const ContactUs = () => {
    return (
      <div id="contactUs">
      <div style={{paddingBottom:"10px"}}> </div>
      <ContactMap />
      </div>
    )
}

export default ContactUs